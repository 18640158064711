import React, { useEffect, SyntheticEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GridApi,
  ICellRendererParams,
  NewValueParams
} from 'ag-grid-community';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

import environment from '../../../../../environment';
import StyledReplenishmentChange from './styles';
import Disable from '../../../../../assets/icons/disable.svg';
import ReviewModal from '../../../components/ReviewModal';
import { IReplenishmentChanges } from '../../../interfaces/IForecasting';

interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  replenishmentChanges: IReplenishmentChanges[];
  setReplenishmentChanges: CallableFunction;
}

interface IReplenishmentChangesProps {
  replenishmentChanges: IReplenishmentChanges[];
  setReplenishmentChanges: CallableFunction;
  gridApi: GridApi | undefined;
  getRowData: () => void;
  actions: boolean;
}

const ReplenishmentChanges: React.FC<IReplenishmentChangesProps> = ({
  replenishmentChanges,
  setReplenishmentChanges,
  gridApi,
  getRowData,
  actions
}) => {
  const handleReplenishmentChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(String(params.data.id));
      const prevReplenishmentChanges = replenishmentChanges.find(
        (obj) => obj.id === params.data.id
      );
      if (prevReplenishmentChanges) {
        setReplenishmentChanges(
          replenishmentChanges.map((obj) =>
            obj.id === params.data.id
              ? {
                  ...obj,
                  newActualReplenishment: params.newValue
                }
              : obj
          )
        );
      } else {
        setReplenishmentChanges([
          ...replenishmentChanges,
          {
            id: params.data.id,
            productCode: params.data.productCode,
            productName: params.data.productName,
            storeCode: params.data.storeCode,
            storeName: params.data.storeName,
            replenishment: params.data.replenishment,
            newActualReplenishment: params.newValue,
            oldActualReplenishment: params.oldValue
          }
        ]);
      }
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              id: params.data.id,
              actualReplenishment: params.newValue
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const removeReplenishmentChange = (
    params: ICustomRemoveCellRendererParams
  ) => {
    const rowDataNode = params.gridApi.getRowNode(String(params.data.id));
    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            actualReplenishment: params.data.oldActualReplenishment
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);
      params.setReplenishmentChanges(
        params.replenishmentChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const clearReplenishmentChanges = () => {
    if (gridApi) {
      replenishmentChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(String(obj.id));
        if (rowDataNode) {
          const transaction = {
            update: [
              {
                ...rowDataNode.data,
                actualReplenishment: obj.oldActualReplenishment
              }
            ]
          };
          gridApi.applyTransaction(transaction);
        }
      });
      setReplenishmentChanges([]);
    }
    toast.warning('Replenishment changes successfully discarded');
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions remove--btn'>
        <Button
          type='button'
          onClick={() => {
            removeReplenishmentChange(params);
          }}
        >
          <img src={Disable} alt='Remove replenishment change' />
        </Button>
      </div>
    );

    return cell;
  };

  const replenishmentValidations = (params: any) => {
    const parsedValue = Number(params.newValue);

    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue % 1 !== 0) {
      return false;
    }
    params.data.newActualReplenishment = parsedValue;

    return true;
  };

  const columnDefs: any = [
    {
      headerName: 'Recommended Replenishment',
      field: 'replenishment',
      minWidth: 100
    },
    {
      headerName: 'Old Actual Replenishment',
      field: 'oldActualReplenishment',
      minWidth: 100
    },
    {
      headerName: 'New Actual Replenishment',
      field: 'newActualReplenishment',
      onCellValueChanged: handleReplenishmentChange,
      valueSetter: replenishmentValidations,
      editable: true,
      minWidth: 100,
      cellEditor: 'agNumberCellEditor'
    },
    {
      headerName: 'Product Code',
      field: 'productCode',
      enableRowGroup: true,
      minWidth: 68
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      enableRowGroup: true,
      minWidth: 100
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      enableRowGroup: true,
      minWidth: 70
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      enableRowGroup: true,
      minWidth: 100
    }
  ];

  if (actions) {
    columnDefs.push({
      headerName: 'Actions',
      minwidth: 100,
      cellRenderer: 'renderRemoveButton',
      cellRendererParams: {
        gridApi: gridApi,
        replenishmentChanges: replenishmentChanges,
        setReplenishmentChanges: setReplenishmentChanges
      }
    });
  }

  const handleSubmitReplenishmentChanges =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const body = replenishmentChanges
        .filter(
          (obj) => obj.newActualReplenishment !== obj.oldActualReplenishment
        )
        .map((obj) => ({
          id: obj.id,
          replenishment: obj.replenishment,
          actualReplenishment: obj.newActualReplenishment
        }));
      axios
        .post(
          `${environment.apiPathForecasting}updateActualReplenishment`,
          body
        )
        .then(() => {
          setLoading(false);
          setOpen(false);
          toast.success('Replenishment values successfully updated');
          setReplenishmentChanges([]);
          getRowData();
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          toast.error(err.message);
        });
    };

  useEffect(() => {
    if (gridApi) {
      const onCellValueChanged = (params: NewValueParams) => {
        if (params.colDef.field === 'actualReplenishment') {
          handleReplenishmentChange(params);
        }
      };

      gridApi.addEventListener('cellValueChanged', onCellValueChanged);

      return () => {
        gridApi.removeEventListener('cellValueChanged', onCellValueChanged);
      };
    }
  }, [gridApi, replenishmentChanges]);

  return (
    <ReviewModal
      handleSubmit={handleSubmitReplenishmentChanges}
      formTitle='Replenishment Changes'
      formDescription='Please review your changes to be saved or discarded.'
      btnTitle='Review'
      classes='btn--w-200-px btn--light-grey'
      warnings={['Pressing the Clear button will discard all changes.']}
      clearForm={clearReplenishmentChanges}
      disabled={replenishmentChanges.length === 0}
    >
      <StyledReplenishmentChange fixed>
        <div className='change_report report__grid ag-theme-balham'>
          <AgGridReact
            rowData={replenishmentChanges}
            rowHeight={30}
            pagination
            defaultColDef={{
              resizable: true,
              sortable: true,
              flex: 1
            }}
            paginationPageSizeSelector={false}
            paginationPageSize={10}
            enableCellTextSelection
            suppressDragLeaveHidesColumns
            suppressRowClickSelection
            getRowId={(params) => String(params.data.id)}
            getRowClass={(params) => {
              if (params.data) {
                if (
                  params.data.newActualReplenishment ===
                  params.data.oldActualReplenishment
                ) {
                  return 'row--disabled';
                }
              }
            }}
            groupDisplayType='groupRows'
            rowGroupPanelShow='always'
            suppressRowGroupHidesColumns
            excelStyles={[
              {
                id: 'numberType',
                dataType: 'Number'
              }
            ]}
            components={{ renderRemoveButton: renderRemoveButton }}
            columnDefs={columnDefs}
          />
        </div>
      </StyledReplenishmentChange>
    </ReviewModal>
  );
};

export default ReplenishmentChanges;
