import React from 'react';

import StyledControls from './styles';
import RemoveSkuGroupModal from '../RemoveSkuGroupModal';
import SkuGroupBulkChangesModal from '../SkuGroupBulkChangesModal';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';

const ControlButtons: React.FC<{
  setRowData: (data: ISkuGroup[]) => void;
  rowData: ISkuGroup[];
  data: ISkuGroup;
}> = ({ setRowData, rowData, data }) => {
  const { id, skuGroup } = data;

  return (
    <StyledControls>
      <SkuGroupBulkChangesModal id={id} skuGroup={skuGroup} />
      <RemoveSkuGroupModal
        id={id}
        skuGroup={skuGroup}
        setRowData={setRowData}
        rowData={rowData}
      />
    </StyledControls>
  );
};

export default ControlButtons;
