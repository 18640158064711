import React from 'react';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import fileDownload from 'js-file-download';

import { dateHourFormatter, boolFormatter } from '../../../../utils';
import CustomAccordion from '../../../../components/CustomAccordion';
import DeliveryGroupList from '../DeliveryGroupList';
import OrderSummary from '../OrderSummary';
import { IOrder, IValidationError } from '../../../../interfaces/IOrder';
import { IUserPermissions } from '../../../../interfaces/IAuthState';
import Error from '../../../../components/Error';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import environment from '../../../../environment';
import CustomButton from '../../../../components/CustomButton';

const Order: React.FC<{
  order: IOrder;
  clearData: CallableFunction;
  permissions: IUserPermissions;
}> = ({ order, clearData, permissions }) => {
  const downloadOrderPayload = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}downloadOrderPayload?orderId=${order.orderId}`,
        {
          responseType: 'text',
          ...environment.params
        }
      );
      fileDownload(atob(res.data), order.oneNetFile || `${order.orderId}.xml`);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  return (
    <CustomAccordion
      key={order.orderId}
      status={order.status}
      primaryTitle={order.orderId}
      secondaryTitle={dateHourFormatter(order.dateTimeRaised)}
      summaryEntries={[
        [
          {
            code: 'Customer Name',
            value: order.invoiceDetails.contact
              ? order.invoiceDetails.contact
              : '-'
          },
          {
            code: 'Account Number',
            value: order.customerId ? order.customerId : '-'
          },
          {
            code: 'E-mail',
            value: order.customerEmail
          }
        ],
        [
          {
            code: 'Origin',
            value: `${order.originName} (${order.origin})`
          },
          {
            code: 'Segment',
            value: `${order.segmentName} (${order.customerSegment})`
          },
          {
            code: 'Legacy format',
            value: boolFormatter(order.isLegacyFormat)
          },
          {
            code: 'Return Ref Number',
            value: order.grsRefNumber ? order.grsRefNumber : '-'
          }
        ]
      ]}
    >
      {order.status === 'REJECTED' ? (
        <>
          {order.validationErrors.map((err: IValidationError) => (
            <Error
              key={err.errorCode}
              title={err.errorCode}
              description={err.errorMessage}
            >
              {err.deliveryGroupNumber ? (
                <GenericEntry
                  code='Delivery group'
                  value={`No. ${err.deliveryGroupNumber}`}
                />
              ) : (
                ''
              )}
              {err.lineItemId && err.productCode ? (
                <GenericEntry
                  code='Line item'
                  value={`No. ${err.lineItemId} - ${err.productCode}`}
                />
              ) : (
                ''
              )}
            </Error>
          ))}
          {permissions.viewOrderPayloadButton && (
            <CustomButton
              title='Download payload'
              classes='btn--w-20'
              handleClick={() => downloadOrderPayload()}
            />
          )}
        </>
      ) : (
        <>
          <OrderSummary order={order} />
          <DeliveryGroupList
            orderID={order.orderId}
            orderSubinventory={order.subinventory}
            deliveryGroups={order.deliveryGroups}
            clearData={clearData}
            returnReferenceCode={order.returnReferenceCode}
            asnFile={order.asnFile}
            permissions={permissions}
          />
        </>
      )}
    </CustomAccordion>
  );
};

export default Order;
