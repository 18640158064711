import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 20px;

  .report__grid {
    height: 350px;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
  .change_report {
    height: 365px;
  }

  .cell__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell__actions.remove--btn {
    justify-content: flex-start;
  }
  .MuiButton-root {
    min-width: 0px;
  }

  .row--disabled {
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.grey};
  }
`;
