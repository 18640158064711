import React, { useEffect, SyntheticEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GridApi,
  ICellRendererParams,
  NewValueParams
} from 'ag-grid-community';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

import environment from '../../../../../environment';
import StyledTierChange from './styles';
import Disable from '../../../../../assets/icons/disable.svg';
import ReviewModal from '../../../components/ReviewModal';
import { ITierChanges } from '../../../interfaces/IStores';
interface ICustomRemoveCellRendererParams extends ICellRendererParams {
  gridApi: GridApi;
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
}

interface ITierChangesProps {
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
  renderStars: (params: ICellRendererParams) => React.JSX.Element;
  gridApi: GridApi | undefined;
  actions: boolean;
}

const TierChanges: React.FC<ITierChangesProps> = ({
  tierChanges,
  setTierChanges,
  renderStars,
  gridApi,
  actions
}) => {
  const handleTierChange = (params: NewValueParams) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(String(params.data.id));
      const prevTierChanges = tierChanges.find(
        (obj) => obj.id === params.data.id
      );
      if (prevTierChanges) {
        setTierChanges(
          tierChanges.map((obj) =>
            obj.id === params.data.id
              ? {
                  ...obj,
                  newTier: params.newValue
                }
              : obj
          )
        );
      } else {
        setTierChanges([
          ...tierChanges,
          {
            id: params.data.id,
            name: params.data.name,
            oldTier: params.oldValue,
            newTier: params.newValue
          }
        ]);
      }
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              id: params.data.id,
              tier: params.newValue
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const removeTierChange = (params: ICustomRemoveCellRendererParams) => {
    const rowDataNode = params.gridApi.getRowNode(String(params.data.id));
    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            tier: params.data.oldTier
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);
      params.setTierChanges(
        params.tierChanges.filter((obj) => obj.id !== params.data.id)
      );
    }
  };

  const clearTierChanges = () => {
    if (gridApi) {
      tierChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(String(obj.id));
        if (rowDataNode) {
          const transaction = {
            update: [
              {
                ...rowDataNode.data,
                tier: obj.oldTier
              }
            ]
          };
          gridApi.applyTransaction(transaction);
        }
      });
      setTierChanges([]);
    }

    toast.warning('Tier changes successfully discarded');
  };

  const renderRemoveButton = (params: ICustomRemoveCellRendererParams) => {
    const cell = (
      <div className='cell__actions remove--btn'>
        <Button
          type='button'
          onClick={() => {
            removeTierChange(params);
          }}
        >
          <img src={Disable} alt='Remove tier change' />
        </Button>
      </div>
    );

    return cell;
  };

  const columnDefs: any = [
    {
      headerName: 'Old Tier',
      field: 'oldTier',
      enableRowGroup: true,
      sortable: true,
      minWidth: 95,
      cellRenderer: 'renderStars',
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'New Tier',
      field: 'newTier',
      enableRowGroup: true,
      onCellValueChanged: handleTierChange,
      sortable: true,
      minWidth: 95,
      cellRenderer: 'renderStars'
    },
    {
      headerName: 'Store Code',
      field: 'id',
      sortable: true,
      flex: 1,
      width: 100
    },
    {
      headerName: 'Store Name',
      field: 'name',
      sortable: true,
      flex: 2,
      width: 150
    }
  ];
  if (actions) {
    columnDefs.push({
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: 'renderRemoveButton',
      cellRendererParams: { gridApi, tierChanges, setTierChanges },
      width: 100
    });
  }

  const handleSubmitTierChanges =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const body = tierChanges
        .filter((obj) => obj.newTier !== obj.oldTier)
        .map((obj) => ({
          id: obj.id,
          tier: obj.newTier
        }));
      axios
        .post(`${environment.apiPathForecasting}updateStoreTier`, body)
        .then(() => {
          setLoading(false);
          setOpen(false);
          toast.success('Tier values successfully updated');
          setTierChanges([]);
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          toast.error(err.message);
        });
    };

  useEffect(() => {
    if (gridApi) {
      const onCellValueChanged = (params: NewValueParams) => {
        if (params.colDef.field === 'tier') {
          handleTierChange(params);
        }
      };

      gridApi.addEventListener('cellValueChanged', onCellValueChanged);

      return () => {
        gridApi.removeEventListener('cellValueChanged', onCellValueChanged);
      };
    }
  }, [gridApi, tierChanges]);

  return (
    <ReviewModal
      handleSubmit={handleSubmitTierChanges}
      formTitle='Store Tier Changes'
      formDescription='Please review your changes to be saved or discarded.'
      btnTitle='Review'
      classes='btn--w-200-px btn--light-grey'
      warnings={['Pressing the Clear button will discard all changes.']}
      clearForm={clearTierChanges}
      disabled={tierChanges.length === 0}
    >
      <StyledTierChange fixed>
        <div className='change_report report__grid ag-theme-balham'>
          <AgGridReact
            rowData={tierChanges}
            rowHeight={30}
            pagination
            defaultColDef={{
              resizable: true,
              sortable: true
            }}
            paginationPageSizeSelector={false}
            paginationPageSize={10}
            enableCellTextSelection
            suppressDragLeaveHidesColumns
            suppressRowClickSelection
            getRowClass={(params) => {
              if (params.data) {
                if (params.data.newTier === params.data.oldTier) {
                  return 'row--disabled';
                }
              }
            }}
            components={{
              renderRemoveButton: renderRemoveButton,
              renderStars: renderStars
            }}
            getRowId={(params) => params.data.id}
            rowGroupPanelShow='always'
            groupDisplayType='groupRows'
            groupRowRendererParams={{
              innerRendererParams: {
                readOnly: true,
                classes: 'rating--shadow-grey'
              },
              innerRendererSelector: (params: any) => {
                if (
                  params.node.field === 'newTier' ||
                  params.node.field === 'oldTier'
                ) {
                  return {
                    component: 'renderStars'
                  };
                }

                return {
                  component: 'agGroupComponent'
                };
              }
            }}
            suppressRowGroupHidesColumns
            excelStyles={[
              {
                id: 'numberType',
                dataType: 'Number'
              }
            ]}
            columnDefs={columnDefs}
          />
        </div>
      </StyledTierChange>
    </ReviewModal>
  );
};

export default TierChanges;
