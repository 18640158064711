import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../../components/ControlledInput';
import environment from '../../../../../environment';
import { ISubInventory } from '../../../../../interfaces/IInventoryItem';
import GenericModal from '../../../../../components/GenericModal';

interface ISubInventoryProps {
  productCode: string;
  productDescription: string;
  channel: string;
  subinventory: ISubInventory;
}

const PreorderReleaseModal: React.FC<ISubInventoryProps> = ({
  productCode,
  productDescription,
  channel,
  subinventory
}) => {
  const [releaseQuantity, setReleaseQuantity] = useState(
    subinventory.stockOnHand - subinventory.allocated
  );
  const [releaseQuantityError, setReleaseQuantityError] = useState('');

  const clearForm = () => {
    setReleaseQuantity(subinventory.stockOnHand - subinventory.allocated);
    setReleaseQuantityError('');
  };

  const handleReleaseQuantityChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const qty: number = parseInt(event.target.value, 10);
    setReleaseQuantity(qty);

    if (
      isNull(event.target.value) ||
      isUndefined(event.target.value) ||
      event.target.value === ''
    ) {
      setReleaseQuantityError('Required field');
    } else {
      setReleaseQuantityError('');
    }
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      try {
        const res = await axios.post(
          `${environment.apiPath}releasePreorders`,
          {
            productCode,
            subinventory: subinventory.subinventory,
            releaseQuantity
          },
          { ...environment.params }
        );

        setReleaseQuantity(subinventory.stockOnHand - res.data.totalAllocated);
      } catch (err: AxiosError | any) {
        toast.error(err.message);
        clearForm();
      } finally {
        setOpen(false);
        setLoading(false);
      }
    };

  return (
    <GenericModal
      btnTitle='Release preorders'
      clearForm={clearForm}
      formTitle={productCode}
      formSubtitle={<h3>{productDescription}</h3>}
      formDescription={`${channel} - ${subinventory.subinventory}`}
      formError={!!releaseQuantityError}
      classes='btn--small'
      handleSubmit={handleSubmit}
    >
      <ControlledInput
        id='releaseQuantity'
        label='Release quantity'
        type='number'
        value={releaseQuantity}
        placeholder='Type release quantity here'
        handleChange={handleReleaseQuantityChange}
        error={releaseQuantityError}
        required
      />
    </GenericModal>
  );
};

export default PreorderReleaseModal;
