import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Tooltip } from '@mui/material';

import EditIcon from '../../assets/icons/edit.svg';

interface IEditTooltipProps {
  params: ICellRendererParams;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  arrow?: boolean;
  followCursor?: boolean;
  enterDelay?: number;
  leaveDelay?: number;
  disableInteractive?: boolean;
}

const EditTooltip: React.FC<IEditTooltipProps> = ({
  params,
  placement,
  arrow,
  followCursor,
  enterDelay,
  leaveDelay,
  disableInteractive
}) => (
  <Tooltip
    title={<img src={EditIcon} alt='Edit' />}
    arrow={arrow}
    placement={placement}
    followCursor={followCursor}
    enterDelay={enterDelay}
    leaveDelay={leaveDelay}
    disableInteractive={disableInteractive}
  >
    <div>{params.value}</div>
  </Tooltip>
);

EditTooltip.defaultProps = {
  placement: 'bottom-start',
  arrow: true,
  followCursor: false,
  enterDelay: 50,
  leaveDelay: 0,
  disableInteractive: true
};

export default EditTooltip;
